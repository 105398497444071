import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';

import { Button } from 'portal-commons';

const FilterToggleButton = ({
  visible = false,
  onClick,
  testId = 'tableAddFilterButton',
  size,
}) => {
  const handleClick = (e) => {
    onClick?.(e);
  };

  return (
    <Button
      variant="standard"
      color="primary"
      onClick={handleClick}
      portal="dca"
      data-testid={testId}
      size={size}
    >
      <FontAwesomeIcon icon={faFilter} size="lg" />
      {visible ? 'Hide' : 'Show'} Filters
    </Button>
  );
};

export default FilterToggleButton;

import React, { ReactNode } from 'react';
import { Flex, BoxV2 as Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

const Field: React.FC<{
  label: string;
  value: string | ReactNode;
  loading?: boolean;
  labelWidth?: number;
}> = ({ label, value, loading, labelWidth = 165, ...props }) => (
  <Flex
    sx={{ fontSize: 'H300', lineHeight: 'normal', color: 't.black70' }}
    {...props}
  >
    <Box as="label" sx={{ fontWeight: 400, width: labelWidth, flexShrink: 0 }}>
      {label}:{' '}
    </Box>
    <Box
      as="span"
      sx={{
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : value}
    </Box>
  </Flex>
);

export default Field;

export const sort = <T>(
  data: T[],
  sortField: keyof T,
  ascendingOrder: boolean
) => {
  return data.slice().sort((a: T, b: T) => {
    const aField = a[sortField];
    const bField = b[sortField];
    if (typeof aField === 'string' && typeof bField === 'string') {
      return ascendingOrder
        ? aField.localeCompare(bField)
        : bField.localeCompare(aField);
    }
    if (typeof aField === 'number' && typeof bField === 'number') {
      return ascendingOrder ? aField - bField : bField - aField;
    }
    return 0;
  });
};

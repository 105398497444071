import React from 'react';
import { Grid } from '@material-ui/core';
import DetailItem from './DetailItem';
import { BoxV2 as Box, Flex } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/pro-regular-svg-icons';
import { convertTimeWithTimezone } from '../../../utils/time';
import VettingScoreTooltip from './VettingScoreTooltip';

import { ENTITY_TYPES } from '../constants';

const getR3kStatus = (optionalAttributes) => {
  return optionalAttributes && optionalAttributes['russell3000']
    ? 'True'
    : 'False';
};

const getTaxExemptStatus = (optionalAttributes) => {
  if (optionalAttributes) {
    if (optionalAttributes['section527']) {
      return '527';
    }
    if (optionalAttributes['taxExemptStatus']) {
      return optionalAttributes['taxExemptStatus'];
    }
  }
  return 'N/A';
};

export default function CampaignBrandDetails(props) {
  const { profile, campaign, brandMNOSuspension } = props;
  const isSoleProprietor = campaign.usecase === 'SOLE_PROPRIETOR';
  const isRedacted = isSoleProprietor && !profile.dpaSigned;
  const brand = campaign.brand ?? {};
  const isPublicProfitBrand = brand.entityType === 'PUBLIC_PROFIT';
  const entityType = brand.entityType
    ? ENTITY_TYPES.find((t) => t.value === brand.entityType)?.label ??
      brand.entityType
    : null;

  const vettingScoreValue = () => {
    if (!Number.isInteger(campaign.brand?.evpVettingScore)) {
      return null;
    }

    const hasFeedback =
      campaign.brand.evpVettingFeedback &&
      campaign.brand.evpVettingFeedback.length > 0;

    return (
      <Box
        as="div"
        sx={{ display: 'inline-flex', alignItems: 'center', height: 14 }}
        data-testid="campaignBrandDetailsVettingScore"
      >
        {campaign.brand.evpVettingScore}
        {hasFeedback && (
          <VettingScoreTooltip data={campaign.brand.evpVettingFeedback} />
        )}
      </Box>
    );
  };

  return (
    <div className="brand details-wrapper" data-testid="campaignBrandDetails">
      <div className="title">
        <h3 className="heading2">
          <Flex sx={{ alignItems: 'center' }}>
            <Box sx={{ mr: 'xs' }}>
              <FontAwesomeIcon icon={faTag} size="lg" />
            </Box>
            <span>Brand Details</span>
          </Flex>
        </h3>
      </div>
      <Grid container>
        <Grid item xs={6}>
          <DetailItem
            title="Legal Company Name"
            xs={5}
            value={campaign.brand?.companyName}
          />
          <DetailItem title="Entity Type" xs={5} value={entityType} />
          <DetailItem
            title={
              campaign.brand.einIssuingCountry === 'US'
                ? `EIN`
                : 'Tax Number/ID'
            }
            xs={5}
            value={campaign.brand?.ein}
          />
          <DetailItem title="Brand ID" xs={5} value={campaign.brand?.uid} />
          <DetailItem
            title="Russell3000"
            xs={5}
            value={getR3kStatus(campaign.brand?.optionalAttributes)}
          />
          <DetailItem
            title="Identity Status"
            xs={5}
            value={campaign.brand?.identityStatus}
          />
          {isSoleProprietor && (
            <DetailItem
              title="First Name"
              xs={5}
              value={isRedacted ? 'Redacted due to PII' : brand.firstName}
            />
          )}
          <DetailItem
            title="Political Committee Locale"
            xs={5}
            value={
              campaign.brand?.optionalAttributes?.politicalCommitteeLocale ??
              'N/A'
            }
          />
          <DetailItem
            title="Brand Support Phone Number"
            xs={5}
            value={isRedacted ? 'Redacted due to PII' : campaign.brand?.phone}
          />
        </Grid>
        <Grid item xs={6}>
          <DetailItem
            title="Brand Name or DBA"
            xs={5}
            value={
              isRedacted ? 'Redacted due to PII' : campaign.brand?.displayName
            }
          />
          <DetailItem
            title="Universal EIN"
            xs={5}
            value={campaign.brand?.universalEin}
          />
          <DetailItem
            title="EIN Issuing Country"
            xs={5}
            value={brand.einIssuingCountry}
          />
          <DetailItem
            title="Website/Online Presence"
            xs={5}
            value={campaign.brand?.website}
          />
          <DetailItem
            title="Tax Exempt Status"
            xs={5}
            value={getTaxExemptStatus(campaign.brand?.optionalAttributes)}
          />
          <DetailItem
            title="Vetting Score"
            xs={5}
            value={vettingScoreValue()}
          />
          {isSoleProprietor && (
            <DetailItem
              title="Last Name"
              xs={5}
              value={isRedacted ? 'Redacted due to PII' : brand.lastName}
            />
          )}
          <DetailItem
            title="Government Entity"
            xs={5}
            value={
              campaign.brand?.optionalAttributes?.governmentEntity
                ? 'True'
                : 'False'
            }
          />
          <DetailItem
            title="Brand Support Email Address"
            xs={5}
            value={isRedacted ? 'Redacted due to PII' : campaign.brand?.email}
          />
        </Grid>
      </Grid>

      {isPublicProfitBrand && (
        <>
          <Box sx={{ mt: 'xxl' }}>
            <div className="title">
              <h3 className="heading3">Business Contact Details</h3>
            </div>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <DetailItem
                title="Email Verified Date"
                xs={5}
                value={convertTimeWithTimezone(
                  brand.businessContactEmailVerifiedDate,
                  'MM/DD/YYYY'
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Box sx={{ mt: 'xxl' }}>
        <div className="title">
          <h3 className="heading3">
            <span>Brand Suspended</span>
          </h3>
        </div>
      </Box>

      <Grid
        container
        className="brandSuspended"
        data-testid="campaignBrandDetailsBrandSuspend"
      >
        {brandMNOSuspension.map((brand) => {
          return (
            <Grid item xs={4}>
              <Grid container className="detail-item">
                <Box
                  className="flex"
                  data-testid={`campaignBrandDetailsBrandSuspend-${brand.displayName.replace(
                    /\s/g,
                    ''
                  )}`}
                >
                  <Box sx={{ paddingRight: 'xs' }}>
                    <h3 className="paragraph primary medium">
                      {brand.displayName}
                    </h3>
                  </Box>
                  <h3 className="paragraph medium">
                    {brand.suspended ? 'Suspended' : 'Not Suspended'}
                  </h3>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { RouteProps, useRouteMatch } from 'react-router-dom';
import { UserClaims } from '@okta/okta-auth-js';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';

import { useAuthContext } from '../contexts';
import { ErrorBoundaryHoc, withAppLayout } from '../hocs';
import { Role } from '../types';
import { toastFlashMessage } from '../utils';
import datadog from '../utils/datadog';

interface PrivateRouteProps extends RouteProps {
  title: string;
  note?: string;
  roles: Role[];
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  title,
  note,
  roles,
  component,
  ...routeProps
}) => {
  const { user: dcaUser, role } = useAuthContext();
  const { oktaAuth, authState } = useOktaAuth();
  const match = useRouteMatch(routeProps);
  const pendingVerification = useRef(false);
  const latestUser = useRef<UserClaims>();
  const WrappedComponent = useMemo(
    () =>
      withAppLayout(ErrorBoundaryHoc(component), {
        title,
        note,
        roles,
      }),
    [title, roles, component, role]
  );

  const logout = useCallback(() => {
    localStorage.clear();
    oktaAuth.signOut();
  }, [oktaAuth]);

  const handleVerification = async () => {
    if (!role || pendingVerification.current || latestUser.current) {
      return;
    }
    pendingVerification.current = true;
    latestUser.current = await oktaAuth.getUser();

    const user = latestUser.current;
    if (user) {
      const latestRole =
        user.groups && user.groups.length > 0 ? user.groups[0] : Role.Viewer;
      if (latestRole !== role) {
        datadog.addAction('identityVerification', {
          currentRole: role,
          newRole: latestRole,
          dcaUserData: dcaUser,
          oktaUserData: user,
        });
        /*
        toastFlashMessage(
          'Re-login required due to user role update',
          'success'
        );
        handleLogout();
         */
      }
    } else {
      logout();
    }
    pendingVerification.current = false;
  };

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.storageArea !== localStorage) {
        return;
      }
      oktaAuth.authStateManager.updateAuthState();
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [oktaAuth]);

  useEffect(() => {
    if (!match) {
      return;
    }

    if (authState.isAuthenticated) {
      handleVerification();
    }
  }, [role, authState.isAuthenticated, match]);

  return (
    <SecureRoute
      {...routeProps}
      component={
        authState.isAuthenticated && role ? WrappedComponent : undefined
      }
    />
  );
};

export default PrivateRoute;

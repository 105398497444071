import { FunctionComponent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { useAuthContext } from '../contexts';
import { Loader } from '../shared_elements';
import { DcaProfile, User } from '../types';
import { toastFlashMessage } from '../utils';
import datadog from '../utils/datadog';
import { globalGetService } from '../utils/globalApiServices';

const Home: FunctionComponent = () => {
  const { setUser, setProfile } = useAuthContext();
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();
  const location = useLocation();

  const logout = () => {
    localStorage.clear();
    oktaAuth.signOut();
  };

  const handleTokenExpired = () => {
    toastFlashMessage('Token expired! please try logging in again', 'error');
    logout();
  };

  const handleUnexpectedError = () => {
    toastFlashMessage('Please try logging in again', 'error');
    logout();
  };

  useEffect(() => {
    (async () => {
      if (authState.isPending) {
        return;
      }
      if (!authState.isAuthenticated) {
        history.push('/login');
        return;
      }

      const userResponse = await globalGetService('user');
      if (userResponse.ok) {
        const user = userResponse.data as User;
        if (user) {
          setUser(user);
          if (!datadog.hasUser()) {
            datadog.setUser(user);
          }
        }
      } else if (userResponse.status === 401) {
        handleTokenExpired();
        return;
      } else {
        handleUnexpectedError();
        return;
      }

      const profileResponse = await globalGetService('dca/dcas/me');
      if (profileResponse.ok) {
        const profile = profileResponse.data as DcaProfile;
        if (profile) {
          setProfile(profile);
        }
      } else if (profileResponse.status === 401) {
        handleTokenExpired();
        return;
      } else {
        handleUnexpectedError();
        return;
      }

      const params = new URLSearchParams(location.search);
      const redirectUri = params.get('redirectUri');
      if (redirectUri) {
        window.location.href = decodeURIComponent(redirectUri);
      } else {
        history.push('/dashboard');
      }
    })();
  }, [authState.isPending, authState.isAuthenticated]);

  return <Loader hocFlag />;
};

export default Home;

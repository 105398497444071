import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons';
import { Flex, BoxV2 as Box } from 'portal-commons';

import BrandAssets from './BrandAssets';

interface ComponentProps {
  brandId: string;
}

const CampaignBrandAssetsSection: FC<ComponentProps> = ({ brandId }) => {
  return (
    <div className="csp details-wrapper">
      <div className="title border-none">
        <h3 className="heading2">
          <Flex>
            <Box mr="xs">
              <FontAwesomeIcon icon={faFileImage} size="xl" />
            </Box>
            <span>Brand Assets</span>
          </Flex>
        </h3>
      </div>
      <BrandAssets brandId={brandId} />
    </div>
  );
};

export default CampaignBrandAssetsSection;

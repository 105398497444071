import { TopBar, useHasPermission } from 'portal-commons';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import { useAuthContext } from '../contexts';

const Header = ({ title, note, authenticated }) => {
  const { user, role } = useAuthContext();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const isProfileAccessible = useHasPermission('profile.view');

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.setItem('logoutSuccess', true);
    oktaAuth.signOut('/login');
  };

  const menus = useMemo(() => {
    return [
      isProfileAccessible ? (
        <TopBar.MenuItem key="profile" onClick={() => history.push('/profile')}>
          View DCA Profile
        </TopBar.MenuItem>
      ) : null,
      <TopBar.MenuItem key="logout" onClick={handleLogout}>
        Logout
      </TopBar.MenuItem>,
    ].filter(Boolean);
  }, [role, isProfileAccessible]);

  const userName = useMemo(() => {
    return localStorage.getItem('okta-token-storage')
      ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken
        ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims
            .name
        : user
        ? user.profile.fullName
        : ''
      : '';
  }, []);

  return (
    <TopBar
      authenticated={authenticated}
      portal="dca"
      username={userName}
      title={title}
      subText={note}
      menus={menus}
    />
  );
};
export default Header;

export const CSP_CAMPAIGNS_PATH = '/csp-campaigns';
export const CSP_CAMPAIGN_DETAILS_PATH = `${CSP_CAMPAIGNS_PATH}/:id`;
export const LEGACY_CSP_CAMPAIGNS_PATH = '/campaigns';
export const LEGACY_CSP_CAMPAIGN_DETAILS_PATH = `${LEGACY_CSP_CAMPAIGNS_PATH}/:id`;

export const DCA_CAMPAIGNS_PATH = '/dca-campaigns';
export const DCA_CAMPAIGN_DETAILS_PATH = `${DCA_CAMPAIGNS_PATH}/:id`;

export const CSP_CAMPAIGNS_DEFAULT_ENTRY = `${CSP_CAMPAIGNS_PATH}?status=ACTIVE&provisional=false`;
export const DCA_CAMPAIGNS_DEFAULT_ENTRY = `${DCA_CAMPAIGNS_PATH}?status=ACTIVE&provisional=false`;
